// MY THEME FONTS SIZE
$size-11: 11px;
$size-12: 12px;
$size-13: 13px;
$size-14: 14px;
$size-15: 15px;
$size-16: 16px;
$size-17: 17px;
$size-18: 18px;
$size-19: 19px;
$size-20: 20px;
$size-22: 22px;
$size-24: 24px;
$size-34: 34px;
$size-60: 60px;

// MY THEME FONTS WEIGHT

$normal: 400;
$medium: 500;
$bold: 600;

// BORDER RADIUS

$radius-0: 0;
$radius-10: 10px;
$radius-20: 20px;
$radius-pill: 100px;
$radius-circle: 50%;

//BORDER

$border: 1px;
$border-2: 2px;

// MY THEME COLORS

$blue: #4F5BE9;
$purple: #480689;
$pink: #D4088C;
$red: #EA494E;
$orange: #E78844;
$yellow: #EFB036;
$green: #2DB666;

// WHITE AND GRAY COLORS

$white:    #fff;
$gray-100: #E6E4E7;
$gray-200: #CCC8D0;
$gray-300: #B3ADB8;
$gray-400: #9992A0;
$gray-500: #807788;
$gray-600: #665F6D;
$gray-700: #4D4752;
$gray-800: #332F37;
$gray-900: #1A181B;
$black:    #000;


// THEME COLORS VARIABLES
$primary:       $red;
$secondary:     $gray-600;
$success:       $green;
$info:          $blue;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;


// ROOT
:root {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --light: #{$light};
  --dark: #{$dark};
  --white: #{$white};
  --gray-200: #{$gray-200};
  --gray-300: #{$gray-300};
  --gray-400: #{$gray-400};
  --gray-500: #{$gray-500};
  --gray-600: #{$gray-600};
  --gray-700: #{$gray-700};
  --gray-800: #{$gray-800};
  --black: #{$black};
  --size-34: #{$size-34};
  --size-60: #{$size-60};
  --blue: #{$blue};
  --red: #{$red};
  --yellow: #{$yellow};
  --green: #{$green};
}

//CUSTOM GRID BREAKPOINTS => 1400 XXL 1550
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1450px,
);

// CUSTOME CONTAINER
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

// IMPORT BOOTSTRAP-V5
@import "../scss/bootstrap/bootstrap.scss";

@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.animate {
    // animation-duration: 1s;
    // animation-fill-mode: both;
    // animation-name: floating;

    //animation: floating 2.5s ease-in-out !important;
    animation: wobble-hor-bottom 0.8s both;
}

@keyframes shimmy {
    from {
        transform: scale(1.5);
        transform: scale3d(1, 1, 1);
    }
    10%,
    20% {
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }
    30%,
    50%,
    70%,
    90% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
    40%,
    60%,
    80% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
    to {
        transform: scale3d(1, 1, 1);
        transform: scale(1);
    }
}

@keyframes floating {
    0% {
        transform: translateY(0%) scale(1);
    }
    50% {
        transform: translateY(10%) scale(0.9);
    }
    100% {
        transform: translateY(0%) scale(1);
    }
}

@keyframes wobble-hor-bottom {
    0%,
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    15% {
        -webkit-transform: translateX(-30px) rotate(-6deg);
        transform: translateX(-30px) rotate(-6deg);
    }
    30% {
        -webkit-transform: translateX(15px) rotate(6deg);
        transform: translateX(15px) rotate(6deg);
    }
    45% {
        -webkit-transform: translateX(-15px) rotate(-3.6deg);
        transform: translateX(-15px) rotate(-3.6deg);
    }
    60% {
        -webkit-transform: translateX(9px) rotate(2.4deg);
        transform: translateX(9px) rotate(2.4deg);
    }
    75% {
        -webkit-transform: translateX(-6px) rotate(-1.2deg);
        transform: translateX(-6px) rotate(-1.2deg);
    }
}

@keyframes bar-1 {
    from {
        height: 0;
    }
    to {
        height: 150px;
    }
}

@keyframes bar-2 {
    from {
        height: 0;
    }
    to {
        height: 100px;
    }
}

@keyframes bar-3 {
    from {
        height: 0;
    }
    to {
        height: 50px;
    }
}

@keyframes blink-1 {
    0%,
    50%,
    100% {
        opacity: 1;
    }
    25%,
    75% {
        opacity: 0;
    }
}


.spinner-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    .text {
        font-size: $size-13;
        font-weight: $medium;
        color: var(--dark);
    }
}
.spinner {
    width: 20px;
    height: 20px;
    position: relative;
    .double-bounce1,
    .double-bounce2 {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: var(--green);
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;

        -webkit-animation: sk-bounce 2s infinite ease-in-out;
        animation: sk-bounce 2s infinite ease-in-out;
    }

    .double-bounce2 {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
    }
    &.gray {
        .double-bounce1,
        .double-bounce2 {
            background-color: var(--gray-700);
        }
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}

@keyframes shaking {
    10%,
    90% {
        transform: translate3d(-2px, 0, 0);
    }
    20%,
    80% {
        transform: translate3d(4px, 0, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(-8px, 0, 0);
    }
    40%,
    60% {
        transform: translate3d(8px, 0, 0);
    }
}


//  FADE OUT
@keyframes fadeOut {
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes fade-in {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}
@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

/*
    // IMPORT 'IBM Plex Sans Arabic' FROM GOOGLE FONTS
    
    REGULAR   :   400
    MEDIUM    :   500
    SEMIBOLD  :   600
    BOLD      :   700

    // THESE ARE THE NAMES IF YOU ARE USING A DESIGN FIILE SUCH AS FIGMA.
*/

@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;700&display=swap');

p {
    font-size: $size-16;
    color: var(--gray-800);
}

@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.hero-block {
    position: relative;
    background-image: url("../images/bg_body.png");
    background-size: cover;
    background-position: center;
    height: 70vh;
    @include media-breakpoint-down(md) {
        height: 40vh;
    }
    &::before {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='1170' height='177' viewBox='0 0 1170 177' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M203.909 122.522C155.257 91.3649 90.869 52.6055 0.501876 3.06364V3.05369C0.501099 2.0336 0.500323 1.01571 0.499546 0L0 0V177H1170V32.1907V0L1169.84 0C1169.89 10.7302 1169.95 21.4605 1170 32.1907C771.845 110.609 476.752 164.962 400.516 174.625C387.202 176.315 362.236 178.956 330.834 174.705C273.126 166.887 232.863 141.08 205.895 123.795C205.225 123.365 204.563 122.941 203.909 122.522Z' fill='%230C0B15'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        width: 100%;
        height: 287px;
        position: absolute;
        bottom: -1px;
        background-size: cover;
        @include media-breakpoint-down(md) {
            height: 100px;
        }
    }
    .block-text {
        display: flex;
        align-items: center;
        gap: 30px;
        padding-top: 100px;
        .number {
            font-size: 130px;
            font-weight: $bold;
            background: -webkit-linear-gradient(#4a2e8d, #d62690);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @include media-breakpoint-down(md) {
                font-size: 50px;
            }
        }
        .sub_body {
            .text-1 {
                font-size: 50px;
                font-weight: $bold;
                @include media-breakpoint-down(md) {
                    font-size: 30px;
                }
            }
            .text-2 {
                font-size: 130px;
                font-weight: $medium;
                color: var(--white);
                height: 46px;
                margin-bottom: 0;
                @include media-breakpoint-down(md) {
                    font-size: 50px;
                }
            }
        }
    }
    .brand_img {
        position: absolute;
        left: 0;
        top: 4%;
        height: 90%;
        object-fit: contain;
        @include media-breakpoint-down(md) {
            top: 44%;
            height: 60%;
        }
    }
}

// ITEM
.item-product-home {
    position: relative;
    overflow: hidden;
    border-radius: 14px;
    //outline: 2px solid rgba($white, 0.05) !important;
    box-shadow: inset -1.5px -1.5px 0px rgba($white, 0.05), inset 1.5px 1.5px 0px rgba($white, 0.04) !important;
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 24px;
    text-align: right;
    padding: 30px;
    margin-bottom: 24px;
    background-color: rgba($white, 0.03);
    @include media-breakpoint-up(md) {
        min-height: 160px;
    }
    .icon-svg {
        img {
            width: 64px;
            height: 64px;
            object-fit: contain;
        }
    }
    .text {
        h2 {
            font-size: 40px;
            font-weight: $bold;
            color: var(--white);
            margin-bottom: 2px;
        }
        p {
            font-size: 16px;
            color: var(--gray-200);
            margin: 0;
            font-weight: $medium;
        }
    }
    &.bg-white {
        background-clip: padding-box;
        box-shadow: none !important;
        background: linear-gradient(to bottom, #4f1970, #d5cfe6);
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: 2px; /* !importanté */
            border-radius: inherit; /* !importanté */
            background: var(--white);
        }
        .text {
            h2 {
                color: $purple;
            }
            p {
                color: var(--gray-800);
            }
        }
    }
    &.item-inside {
        background-color: transparent !important;
        box-shadow: none !important;
        padding: 20px 0;
        min-height: 0;
        border-bottom: 1px dashed rgba($white, 0.15);
        margin: 0 !important;
        border-radius: 0 !important;
    }
}

.item-lg-box {
    height: calc(100% - 24px);
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &.bg-pink {
        background: linear-gradient(90deg, #D62690 0%, #DD67A7 98.38%) !important;
        border: 2px solid rgba($white, .8);
        p {
            color: rgba($white, .8) !important;
        }
    }
    .icon-svg {
        img {
            width: 125px;
            height: 125px;
            object-fit: contain;
        }
    }
    .text {
        h2 {
            font-size: 64px;
        }
    }
}

.loader-page {
    position: fixed !important;
    width: 100%;
    height: 100%;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0c0b15;
    z-index: 9999;

    &.hidden {
        animation: fadeOut 1s;
        animation-fill-mode: forwards;
        pointer-events: none;
    }
}

.block-brands {
    position: relative;
    overflow: hidden;
    margin-bottom: 40px;
    border-radius: 20px;
    padding: 50px;
    box-shadow: inset -1.5px -1.5px 0px rgba($white, 0.15), inset 1.5px 1.5px 0px rgba($white, 0.2) !important;
    .img-brand {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 16px;
        .commerce {
            width: 200px;
        }
        .logo {
            width: 220px;
            height: 100%;
            &.md {
                width: 190px;
            }
            @include media-breakpoint-down(md) {
                width: 130px !important;
            }
        }
    }
    &.purple {
        background-color: #5c2582;
    }
    &.pink {
        background-color: #de379e;
    }
}

.title-main {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-top: 60px;
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: var(--white);
        z-index: -1;
    }
    .circle {
        width: 10px;
        height: 10px;
        border-radius: 100px;
        border: 2px solid var(--white);
        position: absolute;
        background-color: #0c0b15;
        right: 0;
        &:last-child {
            right: inherit;
            left: 0;
        }
    }
    h3 {
        font-size: 32px;
        color: var(--white);
        font-weight: $bold;
        padding: 20px 30px;
        border-radius: 100px;
        border: 2px solid var(--white);
        width: max-content;
        background-color: #0c0b15;
        min-width: 40%;
        @include media-breakpoint-down(sm) {
            font-size: 24px;
        }
    }
    &.bg-purple {
        h3 {
            background-color: $purple;
        }
    }
}

.block-video {
    border-radius: 14px;
    background-color: var(--white);
    height: calc(100% - 24px);
    position: relative;
    overflow: hidden;
    padding: 0;
    @include media-breakpoint-down(lg) {
        height: 500px;
        margin-bottom: 24px;
    }
    @include media-breakpoint-down(sm) {
        height: 320px;
    }
    video {
        width: 100%;
        height: 100%;
        @include media-breakpoint-up(lg) {
            position: absolute;
        }
    }
}

.block-subscribe {
    background-color: var(--white);
    border-radius: 14px;
    padding: 50px 40px;
    border: 1px solid $pink;
    margin-top: 50px;
    .bg-aflak {
        img {
            height: 240px;
            @include media-breakpoint-down(sm) {
                height: 100%;
                width: 100%;
            }
        }
    }
    .title {
        h4 {
            font-size: 28px;
            font-weight: $bold;
            color: var(--dark);
            margin-bottom: 6px;
        }
        p {
            font-size: 20px;
            color: $pink;
            font-weight: $medium;
            margin: 0;
        }
        .form-group {
            margin-top: 24px;
            input {
                background-color: var(--light);
                border-radius: 10px;
                height: 50px;
                border: 0;
                padding: 20px;
                color: var(--dark);
                font-weight: $medium;
                font-size: $size-16;
                &::placeholder {
                    font-size: 14px;
                    color: var(--gray-400);
                    font-weight: $normal;
                }
            }
        }
        .btn-submit {
            background-color: var(--dark);
            color: var(--white);
            border-radius: 10px;
            font-size: $size-15;
            font-weight: $medium;
            padding: 10px 20px;
            margin-top: 20px;
        }
    }
}
.content-first-screen {
    margin-top: 60px;
}
footer {
    padding-top: 50px;
    .block-footer {
        padding: 30px 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        border-top: 2px solid var(--white);
        flex-direction: row-reverse;
        text-align: left;
        .logo-tts {
            img {
                width: 150px;
            }
        }
        .link-tts,
        .twitter {
            font-size: $size-18;
            display: block;
            font-weight: $bold;
            color: var(--white);
            text-decoration: none;
            img {
                margin-right: 6px;
            }
        }
        .links {
            display: block;
            margin-left: 20px;
        }
        .soical-app {
            margin-left: 60px;
            @include media-breakpoint-down(md) {
                margin-left: 0;
                margin-top: 24px;
            }
            a {
                font-size: $size-18;
                display: block;
                font-weight: $bold;
                color: var(--white);
                text-decoration: none;
                direction: ltr;
                img {
                    margin-right: 6px;
                }
            }
        }
    }
}

#particles-js {
    position: absolute;
    pointer-events: none;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    line-height: 0;
}

.content-masonry-grid {
    //margin: 32px auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 32px;
    margin-top: 60px;
    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(3, 1fr);
    }
    .tts-block-product {
        padding: 30px;
        border-radius: 20px;
        background-color: rgba($white, 0.03);
        border: 2px solid rgba($white, 0.8);
        &.bg-purple {
            background: linear-gradient(90deg, #5c2582 0%, #7e479c 100%);
        }
        .img-brand-pt {
            width: 100%;
            height: 80px;
            object-fit: contain;
            margin-bottom: 20px;
        }
        .content_items {
            .item-inside {
                &:last-child {
                    border: 0 !important;
                }
            }
        }
    }
}
